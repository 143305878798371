import React from 'react';

import { BrowserRouter as Router, Switch, Route, HashRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Home from "./pages/home";
import Programacion from "./pages/programacion";
import Blog from "./pages/blog";
import Contactenos from "./pages/contactanos";
import Preguntasfrequentes from "./pages/preguntasfrequentes";
import Header from "./components/header";
import Footer from "./components/footer";
import Peticion from "./pages/peticion"
import Noticiascristianas from "./pages/noticiascristianas";


const useStyles=makeStyles({});
// export default function App() 
export default function App() {
  const classes=useStyles();
   
  return (
    <div className={classes.container}>
      <Header/>
            <Switch> 
            <Route exact path="/" component={Home} />
            <Route exact path="/programacion" component={Programacion} />
            <Route exact path="/preguntasfrequentes" component={Preguntasfrequentes}/>
            <Route exact path="/peticion" component={Peticion}/>
            <Route exact path="/blog" component={Blog}/>
            <Route exact path="/contactanos" component={Contactenos}/>
            <Route exact path="/noticiascristianas" component={Noticiascristianas}/>
          </Switch> 
             
   <Footer/>
  </div> 
  );
};

 
 
