import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import Grid from '@material-ui/core/Grid';
import IframeComponent from '../../components/iframeComponent/IframeComponent';
import Typography from '@material-ui/core/Typography';

// class noticiascristianas extends Component {
    
    const noticiascristianas=() => {
   
   // render(){
        return(
            <Grid container spacing={0} alignItems="center" justify="center" >   
            <Grid item xs={12} md={6} align="center">  
            <Typography variant="h1" >
                    
                
                <iframe 
                src="https://www.bibliatodo.com/NoticiasCristianas/rss-noticias/" 
                height="510"
                 width="100%"
                 frameborder="no"                    
                 allowTransparency="true" 
                 /> 
                 </Typography>           
            </Grid>
           </Grid> 
        );
   // }
}



export default noticiascristianas;
