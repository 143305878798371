import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import  "bulma/css/bulma.min.css"
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
const baseUrl=document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement=document.getElementById('root');

ReactDOM.render(
    
//  <React.StrictMode>
//     <App />
//   </React.StrictMode>,
<BrowserRouter basename={baseUrl}>
 <App/>
 
</BrowserRouter>
, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
 serviceWorker.unregister();
