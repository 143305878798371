// export function getCountries()
export const Countries={
    countries:[
    {
        "name": "Estados Unidos",
        "code": "USA"
    },
    {
        "name": "Canada",
        "code": "CAN"
    },
    {
        "name": "Colombia",
        "code": "COL"
    },
    {
        "name": "Mexico",
        "code": "mex"
    },
    {
        "name": "Costa Rica",
        "code": "CR"
    },
    {
        "name": "Panama",
        "code": "PAN"
    },
    {
        "name": "El Salvador",
        "code": "SV"
    },
    {
        "name": "Filipinas",
        "code": "FI"
    },
    {
        "name": "Francia",
        "code": "FR"
    },
    {
        "name": "Espnna",
        "code": "ESP"
    },
    {
        "name": "Argentina",
        "code": "AR"
    },
    {
        "name": "Armenia",
        "code": "AM"
    },
    {
        "name": "Aruba",
        "code": "AW"
    },
    {
        "name": "Australia",
        "code": "AU"
    },
    {
        "name": "Austria",
        "code": "AT"
    },
    {
        "name": "Azerbaijan",
        "code": "AZ"
    },
    {
        "name": "Bahamas",
        "code": "BS"
    },    
    {
        "name": "Bangladesh",
        "code": "BD"
    },
    {
        "name": "Barbados",
        "code": "BB"
    },
    {
        "name": "Belarus",
        "code": "BY"
    },
    {
        "name": "Belgica",
        "code": "BE"
    },
    {
        "name": "Belize",
        "code": "BZ"
    },
  
    {
        "name": "Bermuda",
        "code": "BM"
    },
  
    {
        "name": "Bolivia",
        "code": "BO"
    }, 
     
    
    {
        "name": "Brazil",
        "code": "BR"
    },
     
    {
        "name": "Brunei Darussalam",
        "code": "BN"
    },
    {
        "name": "Bulgaria",
        "code": "BG"
    },
    
    {
        "name": "Burundi",
        "code": "BI"
    },
    
    {
        "name": "Cameroon",
        "code": "CM"
    },
 
    
    {
        "name": "Chile",
        "code": "CL"
    },
    {
        "name": "China",
        "code": "CN"
    },
 
    {
        "name": "Cuba",
        "code": "CU"
    },
   
    {
        "name": "Republic Dominicana",
        "code": "DO"
    },
    {
        "name": "Ecuador",
        "code": "EC"
    } 
    
]
}

export default Countries