import React, {Component} from 'react';
 

const Blog=() => {
   
    
        return(
            <div className="blog"><p>Blog pages</p></div>
        );
    }
 



export default Blog;
