import React, {Component, useState,useEffect} from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';  
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Icon from '@material-ui/core/Icon';
import { BrowserRouter as Router, Switch, Route, HashRouter } from "react-router-dom";
import Blog from "../../pages/blog";
import Contactenos from "../../pages/contactanos";
import Home from "../../pages/home";
import Programacion from "../../pages/programacion";
import Peticion from "../../pages/peticion";
import Preguntasfrequentes from "../../pages/preguntasfrequentes";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {Menu,MenuItem,Drawer,Link } from '@material-ui/core';
import { createBrowserHistory } from "history";
import { Link as RouterLink} from "react-router-dom";

import  HeaderImage  from '../../components/headerimage';
 


const linkMenuData=[
  {
    label:"Inicio",
    href:"/",
  },
  /*{
    label:"programacion",
    href:"/programacion",
  },*/
  {
    label:"Preguntas frequentes",
    href:"/preguntasfrequentes",
  },
  {
    label:"Peticion de oracion",
    href:"/peticion",
  },
  /*{
    label:"blog",
    href:"/blog",
  },*/
  {
    label:"Contactenos",
    href:"/contactanos",
  },
  {
    label:"Noticias",
    href:"/noticiascristianas",
  },
];



//icons/Menu
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,  
   
    },
    header: {
      paddingRight:"0px",
      paddingLeft:"8px",
      backgroundColor:"#f6e049",      
      "@media (max-width:900px)":{
        paddingLeft:0,
      },
    },
    cards:{
      height: 345, 
      width:"100%",
    },
    media: {
      height: 350,
      width:"100%",
      },
    menuButton1: {
      marginRight:theme.spacing(1),
      backgroundColor:"#4d1410",
    },
    title: {
      flexGrow: 1,
    },
    appbar_1: {
      flexGrow: 1,     
      backgroundColor:"#4d1410",
      borderTop: 'solid 3px #998643',      
      height:50,
    },
      socialmedia:{
        color:"#FFC300",
        fontSize:50,
        margin:3, 
      },
      headerButtonStyle:{
        display: "flex" , 
        marginLeft:530,       
        justifyContent:"space-between",  
      },
      menuButton:{
        fontFamily:"Open Sans, sans-serif",
        fontWeight:600,
        color:"#1c2833",
        textAlign:"left",
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      buttonMedia:{
        width:120,
        height:50,
        borderRadius:'10',
        margin:1
      },
      drawerContainer:{
        padding:"20px 30px",
      },
      toolbar:{
         display:"flex",
         justifyContent: "space-between",
      },
  }));

  //export default function ButtonAppBar() Header = ( props ) => 
  const Header = () =>  {  
        const  history  = createBrowserHistory({forceRefresh:true});     
        const classes = useStyles();        
        const {header,drawerContainer,menuButton, toolbar} = useStyles(); 
        const [menuOpen,setMenuOpen]=React.useState(null);
        const [anchorEl, setAnchorEl]=React.useState(null);
         const open=Boolean(anchorEl); 

         const [state,setState]=useState({
           mobileView:false,
           drawerOpen:false,
         }) ;
         const { mobileView, drawerOpen }=state;

        useEffect(()=>{
          const setResponsiveness=()=>{
            return  window.innerWidth<900
            ? setState((prevState)=>({...prevState,mobileView:true}))
            : setState((prevState)=>({...prevState,mobileView:false}));
           };
            setResponsiveness();
            window.addEventListener("resize",()=>setResponsiveness());
          },[]);       
        
        const handleMenu = (event) => {
            setAnchorEl(event.currentTarget);
           
        }
        const handleClose = () => {
            setAnchorEl(null);
        }
       const handleButtonClick=(pageURL) => {            
           history.push(pageURL);         
       } 
       const SharingOnSocialNetwork=(SocialNetwork) =>{
        //<SocialShareFacebok/>
       }  
       const displayDesktop=() => {
         return (
            <Toolbar className={toolbar}>
              {drawerLogo}
              <div>{getMenuButtons()}</div>
            </Toolbar>
         );
       };

       const displayMobile = () => {
       const handleDrawerOpen = () => setState((prevState)=>({...prevState, drawerOpen:true}));
       const handleDrawerClose =() => setState((prevState)=>({...prevState,drawerOpen:false}));
      
        return (
          <Toolbar>
            <IconButton
            {...{
              edge:"start",
              color:"#6E2C00",                                                        
              "aria-label":"menu",
              "aria-haspopup":"true",
              docked:"true",
              onClick:handleDrawerOpen,
            }}
            >
            <MenuIcon/>  
            </IconButton>
              <Drawer
               {...{
                 anchor:"left",
                 open:drawerOpen,
                 onClose:handleDrawerClose,
               }}
              >
              <div className={drawerContainer}>{getDrawerChoices()}</div>  
              </Drawer>
              <div>{drawerLogo}</div>
          </Toolbar>
        );
      };
      const getDrawerChoices=()=>{
        return linkMenuData.map(({label,href})=>{
          return(
            <Link
             {...{
               component:RouterLink,
               to:href,
               color:"inherit",
               style:{textDecoration:"none"},
               key:label,
             }}>
            <MenuItem>{label}</MenuItem>
            </Link>
          );
        });
      };

      const drawerLogo=(
        <img src={process.env.PUBLIC_URL + '/img/logo_1.jpg'} alt="logo"/>
      );

      const getMenuButtons=()=>{
        return linkMenuData.map(({label,href})=>{
          return (
            <Button
            {...{
              key:label,
              color:"inherit",
              to:href,
              component: RouterLink,
              className: menuButton,
            }}
            >
             {label}
            </Button>
          );
        });
      };
    return (
       <header>      
        <AppBar className={header} position="fixed" >   
          { mobileView ? displayMobile():displayDesktop()}                
        </AppBar>
      <Grid container spacing={10} justify="space-around"
      align-content="space-around"
      grid-auto-flow="row"
      align-items="start">
    <Grid item xs={12}  >     
    <HeaderImage title="Home"/> 
    
      {/*<<Card className={classes.cards}>
    
        CardMedia
            component="img"
           
            src={process.env.PUBLIC_URL + '/img/headerpicture.jpg'}          
  />      
            
  </Card>*/}  
  </Grid> 
    <Grid item xs={12}>
      
          <iframe 
            id="radiostation"
            width="100%" 
            height="75" 
            src="https://s1.citrus3.com:2000/AudioPlayer/RadioCristianaLatinaenCanada"         
            frameborder="no"    
            allowTransparency="true" >                
            </iframe>
      
    </Grid>
  </Grid>
     <Grid container 
      spacing={3}
      justify="center">     
     <Grid item xs={12}       
      align="center">
     <Typography variant="h4" >
          Gracias por Compartirnos
         </Typography>
         <Button className={classes.buttonMedia} 
          onClick="SocialShareFacebok()"        
          variant="outlined"
         color="secondary"  
         size="large"      
         startIcon={<FacebookIcon fontSize="large"/>}
         >FaceBook         
         </Button>      
         <Button className={classes.buttonMedia}
         onClick={()=>SharingOnSocialNetwork("Twitter")}
         variant="outlined"
         color="secondary" 
         size="large"        
         startIcon={<TwitterIcon fontSize="large"/>}
         >Twitter
         </Button>         
      </Grid>
     </Grid>
  
    </header>
    );
   };  
     
  
export default Header;
