import React from 'react';
import { useFormContext, Controller} from 'react-hook-form';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';

// export default ({onChange, options, value, classname})=>{
//     const defaultValue=(options, value)=>{
//         return options ? options.find(option=>option.value===value) : "";
//     };
//     return(
//         <div className={classname} >
//             <Select 
//             style={{margin:"12px"}}
//             autoWidth={true} 
//             variant="outlined"  
//               value={defaultValue(options,value)}
//               onChange={value=>{
//                   onChange(value)
//               }} 
//               options={options}
//             />
//         </div>
//     )
// }

const MuiSelect=(props)=>{
    const {label,name,options}=props;

 return (
     <FormControl fullWidth={true}>
         <InputLabel htmlFor={name}>
             {label}
         </InputLabel>
         <Select id={name} {...props}>
             <MenuItem value="">
                <em>None</em>
             </MenuItem>
             {options.map((item)=>(
                 <MenuItem key={item.value} value={item.value}>
                     {item.label}
                 </MenuItem>
             ))}
         </Select>
     </FormControl>
 );
};

function CustomSelect(props){
    const { control }=useFormContext();
    const { name,label }=props;
    return (
        <React.Fragment>
            <Controller
                as={MuiSelect}
                control={control}
                name={name}
                label={label}
                defaultValue=""
                {...props}
            />
        </React.Fragment>
    )
}
export default CustomSelect;