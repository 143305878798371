import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightBold,
      fontSize:15,
      lineHeight:1.5,
      h5:{
          fontWeight:600,
          
      },
    },
  }));
  //const Contactanos=() => 
  const Preguntasfrequentes=() => {
    const classes = useStyles();

    return (
        
    //       {/* <Grid container spacing={2}>     
         
            
    //   <Grid item xs={12} md={6} 
    //        justify="center" 
    //        >      */}
    <Grid container spacing={0} alignItems="center" justify="center">
        <Grid  item xs={12} md={6}
            display="flex"  
            justify="center" 
            alignItems="center"> 
            <Typography variant="body1" >
                Aqui pudiera encontrar las preguntas mas frequentes acerca de la radio cristiana latina, 
                si ud no encuentra la respuesta a su pregunta o aun tiene alguna duda acerca de la radio Cristiana, 
                por favor conctatenos  a traves del formulario de contacto a usando nuestra red social de facebook.                
            </Typography>
        </Grid> 
      <Grid item xs={12}></Grid>  
    <Grid item xs={12} md={6}>                 
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className={classes.heading}>Por donde transmite la radio cristiana?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                                  Nosotros transmitimos a traves de la Red de Internet en formato Digital las 24/7 dias de la semana.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Como yo puedo escuchar la radio?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                                 Para escucharnos tiene diferentes opciones.
                                 -Usando el control del sitio web.
                                 -Usando la aplication para mobiles  My Tuner Radio.
                    </Typography>
                </AccordionDetails>
            </Accordion>
          
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography className={classes.heading}>En que idioma transmiten? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                                 Todo nuestros programas y musicas es en espannol.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                <Typography className={classes.heading}>En que paises se escucha la radio ? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                               En todos los paises ya que transmitimos por Internet, usando una plataforma independiente de las redes sociales existentes,
                               podemos ser escuchado en Cualquier pais que se encuentre, solo necesitaria una conexion a internet para acceder al sitio web or usando la aplicacion my Tuner Radio.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography className={classes.heading}>Puedo escucharla en mi dispositivo movil? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                      Si puede escucharnos a tarves de su dispositivo movil para apple or para android, usando la aplicacion my tuner Radio.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div> 
        </Grid>
        </Grid>        
    );
}


export default Preguntasfrequentes;
