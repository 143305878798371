import React, {Component,useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
 




const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    cards:{
        height: 400, 
    },
    media: {
        height: 450,
      },
      mediainternalcard: {
        height: 150,
        width:150,
        display:"flex",
        justifyContent:"center"
      }, 
      mediaconcurso: {
        height: 200,
        width:450       
      }, 
      formsStyle:
      {
       '& .MuiFormControl-root':{
           width:'80%',
           margin:theme.spacing(2) 
       }
      },
      button:{
          margin:theme.spacing(1),
      },
      input:{
          display:'none',
      },
  }),
);

const validationSchema=yup.object({
  nombrecompleto:yup
               .string('Por favor entre su nombre completo')                
               .matches('[a-zA-Z]',"solo caharacteres ")             
               .required('Nombre completo es requerido'),
  correoelectronico:yup
               .string('Por favor entre su correo electronico')
               .email('Entre un correo electronico valido')             
               .required('Correo electronico es requerido'),

  });
const Contactanos=() =>  {
    const classes = useStyles();
      const formik=useFormik({
        initialValues:{
          nombrecompleto:'',
          correoelectronico:'',       
          asunto:'',
          mensaje:'',
        },
        validationSchema: validationSchema,
        onSubmit:(values) => {
          alert(JSON.stringify(values,null,2));        
          formik.resetForm();
          formik.setSubmitting(false);      
        },
      });   
        return(
        <Grid container spacing={2}>            
          <Grid item xs={12}></Grid> 
          <Grid item xs={12} md={6} align="center"
                justify="center"        
                justifyContent="center"
                alignItems="center">
              <img src={process.env.PUBLIC_URL + '/img/contact.jpg'} alt="logo"/>
          </Grid>      
           <Grid item xs={12} md={6}>  
           
              <Typography variant="body1" component="p">
               Si desea saber mas de nosotros o esta interesado en contactarnos por cualquier motivo, por favor rellene el siguiente formulario          
              </Typography>          
              <form onSubmit={formik.handleSubmit} className={classes.formsStyle}>

                <TextField   
                variant="outlined" 
                fullWidth="true" 
                type="text"  
                label="Nombre Completo" 
                id="nombrecompleto"
                name="nombrecompleto"
                value={formik.values.nombrecompleto}                 
                onChange={formik.handleChange}
                error={formik.touched.nombrecompleto && Boolean(formik.errors.nombrecompleto)}            
                helperText={formik.touched.nombrecompleto && formik.errors.nombrecompleto}
                />

                <TextField   
                variant="outlined"
                label="Correco Electronico"
                id="correoelectronico"
                name="correoelectronico"
                value={formik.values.correoelectronico}
                onChange={formik.handleChange}
                type="email"
                error={formik.touched.correoelectronico && Boolean(formik.errors.correoelectronico)}            
                helperText={formik.touched.correoelectronico && formik.errors.correoelectronico}
                />

                <TextField  
                variant="outlined" 
                label="Asunto" 
                name="asunto"
                id="asunto"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.asunto}/>

                <TextField  
                variant="outlined" 
                label="Mensaje"
                id="mensaje"
                name="mensaje"
                onChange={formik.handleChange}
                multiline 
                rows={4}/> 
                <Grid>   
                <Button
                type="submit"
                   variant="contained" 
                   color="primary"
                   style={{ margin:"12px", width:"190px"}}
                   classname={classes.button}
                   >{formik.isSubmitting ? 'Enviando...' :  'Enviar Ahora'}
                </Button>
                </Grid> 
          </form>
          </Grid>
       
          </Grid>
        );
    }
// }



export default Contactanos;
