import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles,createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/core/Menu';
import IframeComponent from '../../components/iframeComponent/IframeComponent';
import footerApp from '../footer/index.css';

{/*const useStyles = makeStyles((theme) =>
  createStyles({
    feetcss: {
      flexGrow: 1,  
      backgroundColor:"#16697A",
    //   borderTop: 'solid 3px #998643',
      height:"300px",
     
    },
    subfooter:{
       backgroundColor:'rgba(0,0,0,0.15)',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);*/}

 
   {/*} export default function AutoGrid() { 
        const classes = useStyles();
        return(
            <AppBar position="sticky"  className={classes.feetcss}>
                  
            <Grid item xs={6} md={6}  >           
                <iframe sandbox=''
                src="https://www.bibliatodo.com/es/online/versiculo-del-dia" 
                height="350"
                 width="350"
                 border="0"  
                 frameborder="no"    
                 allowTransparency="true"           
                 />           
             </Grid>
             
           <Toolbar> 
            
          </Toolbar>          
         </AppBar>        
        );
    }*/}

 
    const Footer = () =>(
      <div className="footercss">
                  
                <iframe sandbox=''
                src="https://www.bibliatodo.com/es/online/versiculo-del-dia" 
                height="350"
                 width="350"
                 border="0"  
                 frameborder="no"    
                 allowTransparency="true"           
                 />           
              
      </div>
    );
    export default Footer;
