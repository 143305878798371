import React, {Component} from 'react';
import PropTypes from 'prop-types'; 
import { createMuiTheme, responsiveFontSizes, MuiThemeProvider, Typography} from "@material-ui/core";


{/* class HeaderImage extends Component {
   
    render(){
        return(
            <div className="headerimage"><p>Header image</p>
                </div>
        );
    }
}

headerimage.propTypes = {
};

headerimage.defaultProps = {
};*/}
let theme = createMuiTheme();
theme=responsiveFontSizes(theme);

const HeaderImage=({title,subtitle}) => {
const heading="Radio Digital Cristiana Latina en Canada";    
const subheading="Transmitimos 24/7 dias de la Semana"  ;     
    return (
     <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/img/headerpicture.jpg'})`,
     backgroundRepeat:'no-repeat',
     backgroundPosition: 'center',
     backgroundSize:'cover',
     opacity:'0.6',
     height:'70vh',
     top:'10px'
     }}>
         {/*<Typography style={{position:'absolute',top:'40%',left:'40%',color:'white'  }} variant="h3"  align="center">
            Radio Digital Cristiana en Canada.
         </Typography>
         <Typography style={{position:'absolute',top:'44%',left:'40%',color:'white'  }} variant="h4"  align="center">
            Transmitiendo 24/7 dias de la semana.
    </Typography>*/}
    <MuiThemeProvider theme={theme}>
          <Typography  style={{position:'absolute',top:'40%',left:'40%',color:'white'  }} variant="h3"  align="center">
            {heading}.
         </Typography>
         {/*<Typography style={{position:'absolute',top:'44%',left:'40%',color:'white'  }} variant="h4"  align="center">
            {subheading}
</Typography>*/}
    </MuiThemeProvider>

    </div>
    )
};
export default HeaderImage;