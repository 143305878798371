import React, {Component} from 'react';
import PropTypes from 'prop-types';

class programacion extends React.Component {
   
    render(){
        return(
            <div className="programacion"><p>Programacion</p></div>
        );
    }
}

programacion.propTypes = {
};

programacion.defaultProps = {
};

export default programacion;
