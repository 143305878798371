import React, {Component,useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { Countries } from '../../data/countries.js'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormHelperText} from '@material-ui/core';
import CustomSelect from '../../components/CustomSelect';
import axios from 'axios';
 


 
const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3),
      },
      formsStyle:
      {
       '& .MuiFormControl-root':{
           width:'80%',
           margin:theme.spacing(2) 
       }
      },
      button:{
          margin:theme.spacing(1),
      },
      input:{
          display:'none',
      },
  }));
  const Options=[
    {value:'Sanida Y Salud',label:'Sanida y Salud', },
    {value:'Matrimonio',label:'Matrimonio', },
    {value:'Familia Cercana',label:'Familia Cercana', },
    {value:'Por mis hijos',label:'Por mis hijos', },
    {value:'Por mi Esposo',label:'Por mi Esposo', },
    {value:'Por mi Esposa',label:'Por mi Esposa', },
    {value:'Por Problemas Financieros',label:'Por Problemas Financieros', } 
  ];
  

const validationSchema=yup.object({
  nombrecompleto:yup
               .string('Por favor entre su nombre completo')                
               .matches('[a-zA-Z]',"solo caharacteres ")             
               .required('Nombre completo es requerido'),
  correoelectronico:yup
               .string('Por favor entre su correo electronico')
               .email('Entre un correo electronico valido')             
               .required('Correo electronico es requerido'),
  asunto:yup
               .string('Por favor especifique el Asunto de su peticion d eoracion')                           
               .required('El asunto de su peticion es requerido'), 

  aceptarterminos:yup.boolean()                      
                      .required('Requerido')
                      .oneOf([true], "Debes aceptar los terminos y condiciones"), 
  ListaMotivoOracion: yup
                    .string('Por favor seleccione  un motivo')                                 
                    .required('Seleccione un motivo es Requerido')                                    
                    .oneOf(['Sanidad','Matrimonio','Familia Cercana','Por mis Hijos','Por mi Esposo','Por mi Esposa','Por Problemas Financieros','Por Salud'],'Seleccione uno de la lista'),


  });
 
const Peticion=()=> {
  
    
      const classes = useStyles(); 
      
    const formik=useFormik({
      initialValues:{
        tipoformulario:'peticiondeoracion',
        nombrecompleto:'',
        correoelectronico:'',
        asunto:'',
        ListaMotivoOracion:'',       
        mensaje:'', 
        aceptarterminos:false      
      },
      validationSchema: validationSchema,
      onSubmit:(values) => {
        alert(JSON.stringify(values,null,2));        
        formik.resetForm();
        formik.setSubmitting(false); 

        axios({
          method:'POST',
          url:'http://api.hardandsoft.ca/send.php',
          headers:{
            'content-type':'application/json'
          },
          data:JSON.stringify(values,null,2)
        })
        .then(result=>{
          console.log(result.data)

          this.setState({
            dataSent:result.data.sent,
          })
          console.log(this.useState)
        })
        .catch(error=>this.setState({
          error:error.message
        }));     
      },
    }); //const Firmk
   
        return(
            <Grid container  spacing={2}  >
                <Grid item xs={12}></Grid>
                 <Grid item xs={12} md={6}> 
                    <Typography variant="h6" component="h2">
                      
                    </Typography>
              <Grid item xs={12} md={6} align="center"
                justify="center"        
                justifyContent="center"
                alignItems="center">
                <img src={process.env.PUBLIC_URL + '/img/peticionoracion.jpg'} alt="logo" />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography variant="h6" component="h2">              
              Envianos tu peticion y nosotros Oramos por ti.
              Al solicitar su oracion, nos comprometemos a orar por usted las veces que sea necesaria hasta ver la gloria y la bendicion de Dios  cumpliendose en sus vidas.
              Estamos interesados en Orar por ti y todas tus necesidades y creemeos por FE que Dios puede sanar todas tus necesidades en Oracion.                      
            </Typography>
            <form   onSubmit={formik.handleSubmit} className={classes.formsStyle}>
                <TextField   
                    variant="outlined" 
                    label="Nombre Completo" 
                    name="nombrecompleto"
                    id="nombrecompleto"
                    onChange={formik.handleChange}
                    value={formik.values.nombrecompleto}
                    error={formik.touched.nombrecompleto && Boolean(formik.errors.nombrecompleto)}            
                    helperText={formik.touched.nombrecompleto && formik.errors.nombrecompleto}
                />

                <TextField   
                    variant="outlined" 
                    label="Correco Electronico"                  
                    name="correoelectronico"
                    id="correoelectronico"
                    onChange={formik.handleChange}
                    value={formik.values.correoelectronico}
                    error={formik.touched.correoelectronico && Boolean(formik.errors.correoelectronico)}            
                    helperText={formik.touched.correoelectronico && formik.errors.correoelectronico}                
                />
                <Grid item xs={12}>
                {/* <Select native autoWidth={true} style={{margin:"12px"}} variant="outlined" label="Pais" required>     
                  <option>Selecione su Pais</option>                    
                      {Countries.countries.map(item => (
                          <option key={item.code} value={item.name}> 
                          {item.name}
                   </option> 
                  ))}                
                </Select>  */}                
                  <Select native autoWidth={true}
                   style={{margin:"12px"}}
                   label="seleccion"
                    variant="outlined"  
                    onChange={formik.handleChange} 
                    name="ListaMotivoOracion" 
                    id="ListaMotivoOracion">
                      <option value="Seleccione su motivo">Seleccione su motivo</option> 
                      <option value="Sanidad">Sanidad</option> 
                      <option value="Matrimonio">Matrimonio</option> 
                      <option value="Familia Cercana">Familia Cercana</option> 
                      <option value="Por mis Hijos">Por mis hijos</option> 
                      <option value="Por mi Esposo">Por mi Esposo</option> 
                      <option value="Por mi Esposa">Por mi Esposa</option>  
                      <option value="Por Problemas Financieros">Por Problemas Financieros</option>                            
                      <option value="Por Salud">Por Salud</option> 
                    </Select> 
                    <FormHelperText error>
                    {formik.touched.ListaMotivoOracion && Boolean(formik.errors.ListaMotivoOracion)}            
                    {formik.touched.ListaMotivoOracion && formik.errors.ListaMotivoOracion} 
                      </FormHelperText> 
                      </Grid>
                    {/* <div    >
                    <CustomSelect
                    variant="outlined" 
                      name="ListaMotivoOracion"                   
                      id="ListaMotivoOracion"
                      className='outlined'
                      onChange={value=>formik.setFieldValue('ListaMotivoOracion',value.value)}
                      value={formik.values.ListaMotivoOracion}
                      option={options}
                    />
                    </div> */}
                    
                     
                    {/* <CustomSelect name="ListaMotivoOracion" label="Motivos" options={Options}/> */}
                   
                    <TextField   
                      variant="outlined" 
                      label="Asunto"  
                      value={formik.values.asunto}
                      name="asunto"
                      id="asunto"
                      onChange={formik.handleChange}
                      error={formik.touched.asunto && Boolean(formik.errors.asunto)}            
                      helperText={formik.touched.asunto && formik.errors.asunto}                     
                    />

                    <TextField 
                       variant="outlined" 
                       multiline rows={4}                        
                       label="Escriba su peticion"
                       value={formik.values.mensaje}
                       name="mensaje"
                       id="mensaje"
                       onChange={formik.handleChange}
                    /> 
                  <FormControlLabel
                    
                    control={
                        <Checkbox
                        checked={formik.values.aceptarterminos}                       
                        name="aceptarterminos"
                        id="aceptarterminos"
                        onChange={formik.handleChange}
                        color="primary"
                                            
                    />}                   
                    name="aceptarterminos"
                    id="aceptarterminos"                    
                    label="Declaro y doy FE que toda la informacion completada en este formulario es verdadera"                   
                  />
                  <FormHelperText error>
                    {formik.touched.aceptarterminos && Boolean(formik.errors.aceptarterminos)}            
                    {formik.touched.aceptarterminos && formik.errors.aceptarterminos} 
                  </FormHelperText>                
                <Grid>   
                <Button
                   variant="outlined" 
                   color="primary"
                   style={{ margin:"12px", width:"290px"}}
                   className={classes.button}
                   type="submit"
                   > Enviar su Peticion Ahora
                </Button>
                </Grid> 
          </form>
          </Grid>  
      </Grid>
      );  
}
export default Peticion;
