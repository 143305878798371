import React, {Component,useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Icon from "@material-ui/core/Icon";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Select from '@material-ui/core/Select';
import { Countries } from '../../data/countries.js'
import IframeComponent from '../../components/iframeComponent/IframeComponent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import  HeaderImage  from '../../components/headerimage';




const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: theme.spacing(3),
    },
    customCard:{
      border:"none", 
      boxShadow:"none",
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    cards:{
        height: 400, 
        border:0,
    },
    media: {
        height: 400,
        width:400,
      },
      mediainternalcard: {
        height: 150,
        width:150,
        display:"flex",
        justifyContent:"center",
        alignitems:"center",
        backgroundColor: "none",
      }, 
      mediaconcurso: {
        height: 200,
        width:450       
      }, 
      formsStyle:
      {
       '& .MuiFormControl-root':{
           width:'80%',
           margin:theme.spacing(2) 
       }
      },
      button:{
          margin:theme.spacing(1),
      },
      input:{
          display:'none',
      },
      errors:{
        color:"red",        
        fontSize: "0.5em",
        display:"relative",
      },
  }),
);

// class home extends React.Component 
const validEmailRegex=RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm=errors=> {
  let valid=true;
  Object.values(errors).forEach(val=>val.length>0 && (valid =false));
  return valid;
 };
 
const validationSchema=yup.object({
  nombrecompleto:yup
               .string('Por favor entre su nombre completo')                
               .matches('[a-zA-Z]',"solo caharacteres ")             
               .required('Nombre completo es requerido'),
  correoelectronico:yup
               .string('Por favor entre su correo electronico')
               .email('Entre un correo electronico valido')             
               .required('Correo electronico es requerido'),

  });
   
  const Home = () => {
    const classes = useStyles();   
    const formik=useFormik({
      initialValues:{
        nombrecompleto:'',
        correoelectronico:'',       
      },
      validationSchema: validationSchema,
      onSubmit:(values) => {
        alert(JSON.stringify(values,null,2));        
        formik.resetForm();
        formik.setSubmitting(false);      
      },
    }); 
  return (

    <div>
      
    <Divider className={classes.divider} />     
      <Grid container spacing={8}
      justify="space-around"
      align-content="space-evenly"
      grid-auto-flow="row"
      align-items="end"
         >       
     
        <Grid item xs={12} align="center"
                justify="center"        
                justifyContent="center"
                alignItems="center">
        <Typography variant="h4" >
           Acerca de la Radio Cristiana           
        </Typography>
          </Grid> 
          <Grid item xs={12} md={6} 
                align="center"
                justify="center"        
                justifyContent="center"
                alignItems="center">
              <img src={process.env.PUBLIC_URL + '/img/homepict.jpg'} alt="logo"/>
          </Grid> 
             
        <Grid item xs={12} md={6} align="left">        
        
        <Typography variant="body1">
        Transmitimos desde la Ciudad de Ottawa Capital de Canada, Nuestro real y unico deseo es llevar la palabra de dios a todos
         en cualquier lugar y circustancias que se encuentre un alma, este donde este una persona que este deseosa de conocer  la palabra de Dios que le sea de alivio y esperanza espiritual en la FE de Nuestro Se&ntilde;or Jesus Cristo.
         Elijimos una variada m&uacute;sica, mensajes cristianos y asi como predicaciones  y ense&ntilde;anzas pastorales como medio de evangelizaci&oacute;n a atraves de nosotros para hacer llevar y conocer el evangelio.,
        </Typography>
        </Grid>
      </Grid>
      
      
       <Divider className={classes.divider} />         
      <Grid container spacing={10} justify="space-around"
      align-content="space-evenly"
      grid-auto-flow="row"
      align-items="end">   
        
        <Grid item xs={12} ></Grid>
        <Grid item xs={12} md={3}  align="center" justify="center"  
                       
               
                alignItems="center"> 
         <Card className={classes.customCard}>
                          
            <CardMedia  component="img"
              className={classes.mediainternalcard}
              src={process.env.PUBLIC_URL + '/img/247internet.jpg'} 
            />
            <CardContent>
              <Typography  variant="h6" component="h2">
              Transmitimos 24/7 por Internet
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p" align="left" >
                Estamos en linea a traves de Internet,
                los 7 dias de la semana, 24 horas al dia, 365 dias del a&ntilde;o
                atraves de los servicios de CITRUS3 como proveedor de Radio.
              </Typography>
            </CardContent>
           
        
          </Card>
        </Grid>   
      <Grid item xs={12} md={3} align="center"> 
          <Card className={classes.customCard}>
             
              <CardMedia
                component="img"
                className={classes.mediainternalcard}
                src={process.env.PUBLIC_URL + '/img/contenidocristiano.jpg'}/>
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                Contenido 100% Cristiano
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" align="left" >
                  Todo el contenido que transmitimos es Cristiano: Musica, Mensajes Biblicos y
                  predicaciones pastorales se alinean al evangelio y ense&ntilde;anzas de Nuestro se&ntilde;or Jesus Cristo
                </Typography>
              </CardContent>
            
            <CardActions>        
            </CardActions>
          </Card>
      </Grid> 
      <Grid item xs={12} md={3} align="center"> 
            <Card className={classes.customCard}>
            
              <CardMedia
                component="img"
                className={classes.mediainternalcard}
                src={process.env.PUBLIC_URL + '/img/gratuito.jpg'} 
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  100% Gratuito
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" align="left" >
                Sin animo de lucros,el unico objetivo nuestro es que ud aprenda
                y tenga un acercamiento con la palabra y ense&ntilde;anzas biblicas evangelica.
                </Typography>
              </CardContent>
             
            <CardActions>            
            </CardActions>
          </Card>
      </Grid>    
      </Grid>
      <Divider className={classes.divider} />     
      <Grid container spacing={10} justify="space-around"
      align-content="space-around"
     
      align-items="end"> 
       <Grid item xs={12} align="center"
                justify="center"        
                justifyContent="center"
                alignItems="center">
        <Typography variant="h4" >
        Eres cantante de Musica Cristiana?            
        </Typography>
       </Grid> 
     
        <Grid item xs={12} md={6} 
                align="center"
                justify="center"        
                justifyContent="center"
                alignItems="center">
              <img src={process.env.PUBLIC_URL + '/img/concursocantar.jpg'} alt="logo"/>
          </Grid>     
      <Grid item xs={12} md={6}>        
            
            <Typography variant="body1">
                Bacon ipsum dolor amet sausage turkey picanha tri-tip cupim turducken pastrami.
                Turkey sausage buffalo prosciutto, tri-tip corned beef picanha pastrami kevin pork. 
                Chuck short loin bresaola, turducken andouille landjaeger rump sirloin. 
                Strip steak doner turkey chicken. Strip steak cupim drumstick tongue, brisket pork loin sirloin.
                Shoulder venison ribeye hamburger kielbasa chuck, jowl porchetta bresaola kevin filet mignon turkey pancetta.
                Burgdoggen picanha ball tip andouille salami.
            </Typography>    
          <Grid container 
              justify="center"                
              >            
            <form onSubmit={formik.handleSubmit}  className={classes.formsStyle}>
            <TextField 
              fullWidth="true" 
              variant="outlined" 
              label="Nombre Completo" 
              id="nombrecompleto"
              name="nombrecompleto"
              type="text"             
              value={formik.values.nombrecompleto}
              onChange={formik.handleChange}
              error={formik.touched.nombrecompleto && Boolean(formik.errors.nombrecompleto)}            
              helperText={formik.touched.nombrecompleto && formik.errors.nombrecompleto}
              />
              <div > 
              <TextField               
               fullWidth="true" 
               variant="outlined" 
               label="Correo Electronico" 
               id="correoelectronico"
               name="correoelectronico"
               type="email"
               onChange={formik.handleChange}
               error={formik.touched.correoelectronico && Boolean(formik.errors.correoelectronico)}            
               helperText={formik.touched.correoelectronico && formik.errors.correoelectronico}
               value={formik.values.correoelectronico}               
               />
              
              </div> 
               <div>
               <Select native autoWidth={true} style={{margin:"12px"}} variant="outlined" label="Pais" required>     
                <option>Su  Pais</option>                    
              {Countries.countries.map(item => (
                  <option key={item.code} value={item.name}> 
                  {item.name}
                 </option> 
              ))}                
              </Select>  
              </div> 
              <TextField  fullWidth="true" variant="outlined" label="Mensaje" multiline rows={4}/>
                <input
                className={classes.input}
                 accept="mp3/*"
                 id="mp3fichero"
                 multiple type="file"
                />
              <label htmlFor="mp3fichero">
                <Button
                  variant="contained" 
                  color="secondary"
                  component="span"
                  className={classes.button}
                  style={{ margin:"12px", width:"80%"}}
                  endIcon={<CloudUploadIcon/>}> Seleccionar Fichero Mp3
                </Button>
              </label>
              <Grid>   
                <Button
                 type="submit"
                 variant="contained" 
                 color="primary"
                 style={{ margin:"12px", width:"190px"}}                   
                 className={classes.button} 
                 > {formik.isSubmitting ? 'Enviando...' : 'Enviar Ahora'}
              </Button>
              
              </Grid> 
        </form>
        </Grid>        
      </Grid>
   
    </Grid> 
    </div>
  )
//};  
} 
  

export default Home;
